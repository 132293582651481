import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled, { css } from "styled-components"

import bgAu from "../../images/FANH-AFLMix-Melbourne.svg"
import bgAu2 from "../../images/FANH-Cricket-Melbourne.svg"
import bgUs from "../../images/FANH-NFL-NewYork.svg"
import bgUk from "../../images/FANH-Soccer-2-London.svg"
import colors from "../../styles/colors"
import below from "../../styles/media"
import Button from "../Button"
import Arrow from "../Icons/Arrow"

const ScrollToBlock = styled.div`
  position: relative;
  > div {
    position: absolute;
    top: -60px;
    ${below.tablet`
      top:-30px;
    `}
  }
`

class HomeHero extends React.Component {
  constructor(props) {
    super(props)
    this.scrollRef = React.createRef()
  }

  render() {
    const { title, desc, button_url, button_text } = this.props
    return (
      <>
        <Wrapper bg={this.props.bg}>
          <CenterWrapper>
            <HeroCopy>{title}</HeroCopy>
            <Copy>{desc}</Copy>
            <ButtonContainer>
              <Button as={props => <Link {...props} />} to={button_url}>
                {button_text}
              </Button>
            </ButtonContainer>
          </CenterWrapper>
          <DownButton
            onClick={e => {
              this.scrollRef.current.scrollIntoView({ behavior: "smooth" })
            }}
          >
            <Arrow />
          </DownButton>
        </Wrapper>
        <ScrollToBlock>
          <div ref={this.scrollRef} />
        </ScrollToBlock>
      </>
    )
  }
}

HomeHero.propTypes = {
  bg: PropTypes.oneOf(["US", "UK", "AU", "AU2"]),
}

HomeHero.defaultProps = {
  bg: "US",
}

export default HomeHero

const bgs = {
  US: bgUs,
  UK: bgUk,
  AU: bgAu,
  AU2: bgAu2,
}

const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  margin-bottom: -40px;
`

const Wrapper = styled.section`
  position: relative;
  background-color: ${colors.darkBlue};
  background-image: url(${bgUs});
  background-size: contain;
  background-position: 60vw 0%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 60px 0 35px;

  ${({ bg }) =>
    bg &&
    css`
      background-image: url(${bgs[bg]});
    `}

  ${below.tablet`
    background-position: 40vw 0%;
    min-height: calc(100vh - 80px);
  `}
`
const HeroCopy = styled.h2`
  font-weight: 800;
  font-size: 75px;
  letter-spacing: 1.5px;
  line-height: 1;
  margin: 0 0 30px;
  ${below.tablet`
    font-size: 38px;
  `}
`

const Copy = styled.h4`
  font-size: 30px;
  letter-spacing: 0.3px;
  text-align: center;
  line-height: 1.16;
  margin: 0 0 30px;
  padding: 0 30px;
  ${below.tablet`
    font-size: 23px;
    br {
      display: none;
    }
  `}
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
`

const DownButton = styled.button`
  width: 83px;
  height: 83px;
  border: 0;
  border-radius: 5px;
  background: transparent;
  margin-top: 20px;
  cursor: pointer;
  color: ${colors.blue};
  transition: all 0.3s linear;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 32px;
    width: 23px;
    fill: currentColor;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`
