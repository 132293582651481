import { Link, graphql } from "gatsby"
import _ from "lodash"
import React from "react"
import { Helmet } from "react-helmet"

import ArrowButton from "../components/ArrowButton"
import { CaseStudyCard, ProductCard } from "../components/Card/types"
// import ClientLogo from "../components/ClientLogo"
import HomeHero from "../components/HomeHero"
import ItemRows from "../components/ItemRows"
import { PageContentsWrapper, PageLayout } from "../components/PageLayout"
import Quote from "../components/Quote"
import SectionTitle from "../components/SectionTitle"
import Spacer from "../components/Spacer"
import StatsSection from "../components/StatsSection"
import GeniusHoldingPage from "./geniusHolding"
import {
  createStaticContent,
  formatStaticContent,
  getPageTitle,
  transformToArray,
  transformToMap,
} from "../data/transform"
import colors from "../styles/colors"
import Slider from "infinite-react-carousel"
import styled from "styled-components"

const SHOW_HOLDING = true

const SliderQuoteStyled = styled.div`
  .carousel-initialized {
    overflow: initial;

    .carousel-item {
      ${({ is_one_item }) =>
        !is_one_item
          ? `
        padding: 12px 25px;
        opacity: .3;
        .quote-content {
          padding: 0 70px 15px;
          color: #ffffff;
        }
        .quote {
          visibility: hidden;
        }
      `
          : null}

      &.active {
        opacity: 1;
        padding: 0;

        .quote-content {
          padding: 0 70px 40px;
        }

        .quote {
          visibility: initial;
        }
      }
    }
  }
`

const IndexPage = props => {
  const locations = transformToArray(
    props.data,
    "locations",
    record => record.inFooter
  )
  const clients = transformToArray(props.data, "clients")
  const products = transformToArray(props.data, "products")
  const cases = transformToArray(props.data, "caseStudies")
  const stats = transformToArray(props.data, "stats")

  const sportMap = transformToMap(props.data, "sports")
  const clientMap = transformToMap(props.data, "clients")
  const productMap = transformToMap(props.data, "products")

  const show_products = _(products)
    .filter("homepage")
    .take(3)
    .value()

  const quotes = transformToArray(props.data, "quotes", record => record.show)

  const content = createStaticContent(props.data, "home")
  const settings_slider = {
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: false,
    arrowsBlock: false,
  }
  // if (SHOW_HOLDING) {
  // 	return <GeniusHoldingPage />
  // }

  return (
    <PageLayout
      hero={
        <HomeHero
          title={formatStaticContent(content, "hero-title")}
          desc={formatStaticContent(content, "hero-desc")}
          button_text={formatStaticContent(content, "hero-button-text")}
          button_url={formatStaticContent(content, "hero-button-url")}
        />
      }
      bg={colors.offWhite}
      locations={locations}
      static_content={content}
    >
      <Helmet>
        <title>{getPageTitle(content)}</title>
      </Helmet>
      <StatsSection
        title={formatStaticContent(content, "stats-title")}
        stats={stats}
      />
      <PageContentsWrapper>
        {/* <Spacer height="60" />
        <SectionTitle
          title={formatStaticContent(content, "clients-section-title")}
          desc={formatStaticContent(content, "clients-section-desc")}
        />
        <Spacer height="40" />
        <ItemRows compact child_limit={0} show_more_increment={0}>
          {_.map(_.take(clients, 12), (client, i) => (
            <Link to={`/clients/${client.client}`} key={i}>
              <ClientLogo>
                <img
                  src={_.get(client, "logo.0.url")}
                  alt={`${client.name} Logo`}
                />
              </ClientLogo>
            </Link>
          ))}
        </ItemRows>
        <ArrowButton to="/clients" /> */}
        <Spacer height="60" />

        <SliderQuoteStyled is_one_item={quotes.length === 1}>
          <Slider {...settings_slider}>
            {quotes.map(quote_item => (
              <Quote
                {...quote_item}
                logo={_.get(clientMap, [
                  _.get(quote_item, "clientID.0"),
                  "logo",
                  0,
                  "url",
                ])}
                key={quote_item.weight}
              />
            ))}
          </Slider>
        </SliderQuoteStyled>

        <Spacer height="60" />
        <SectionTitle
          title={formatStaticContent(content, "work-section-title")}
          desc={formatStaticContent(content, "work-section-desc")}
        />
        <ItemRows>
          {_.map(_.take(cases, 3), (caseStudy, i) => (
            <CaseStudyCard
              key={i}
              caseStudy={caseStudy}
              clientMap={clientMap}
              productMap={productMap}
              sportMap={sportMap}
            />
          ))}
        </ItemRows>
        <ArrowButton to="/work" />
        <Spacer height="60" />
        <SectionTitle
          title={formatStaticContent(content, "products-section-title")}
          desc={formatStaticContent(content, "products-section-desc")}
        />
        <ItemRows>
          {_.map(show_products, (prod, i) => {
            return (
              <ProductCard
                key={i}
                title={`${prod.name}`}
                linkAddress={`/products/${prod.product}`}
                desc={prod.longDescription}
                icon={_.get(prod, "icon.0.url")}
              />
            )
          })}
        </ItemRows>
        <ArrowButton to="/products" />
      </PageContentsWrapper>
    </PageLayout>
  )
}

export const query = graphql`
  query HomePageQuery {
    locations: allAirtable(filter: { table: { eq: "Locations" } }) {
      ...LocationsFragment
    }
    sports: allAirtable(filter: { table: { eq: "Sports" } }) {
      ...SportsFragment
    }
    clients: allAirtable(filter: { table: { eq: "Clients" } }) {
      ...ClientsFragment
    }
    products: allAirtable(filter: { table: { eq: "Products" } }) {
      ...ProductsFragment
    }
    caseStudies: allAirtable(
      filter: { table: { eq: "CaseStudies" }, data: { Disable: { ne: true } } }
    ) {
      ...CaseStudiesFragment
    }
    quotes: allAirtable(filter: { table: { eq: "Quotes" } }) {
      ...QuotesFragment
    }
    stats: allAirtable(filter: { table: { eq: "FanHubStats" } }) {
      ...StatsFragment
    }
    static_content_all: allAirtable(
      filter: { table: { eq: "StaticContent" }, data: { page: { eq: "all" } } }
    ) {
      ...StaticContentFragment
    }
    static_content_home: allAirtable(
      filter: { table: { eq: "StaticContent" }, data: { page: { eq: "home" } } }
    ) {
      ...StaticContentFragment
    }
  }
`

export { all_static } from "../data/queries"

export default IndexPage
