import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import colors from "../../styles/colors"

export default props => (
  <Wrapper {...props}>
    <svg width="14px" height="25px" viewBox="0 0 14 25">
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-23.000000, -17.000000)" fill="currentColor">
          <path
            d="M39.5032081,23.4284687 L35.7488543,27.178485 L31.4665445,31.4411989 L30.1613199,32.7449155 C30.1026581,32.8035095 30.0439963,32.876752 30,32.9499946 C29.9560037,32.876752 29.8973419,32.8035095 29.8386801,32.7449155 L20.5114574,23.4284687 C19.939505,22.8571771 19.0009166,22.8571771 18.4289643,23.4284687 C17.8570119,23.9997602 17.8570119,24.9226158 18.4289643,25.4939074 L27.7855179,34.8396512 L28.9734189,36.0115313 C29.5453712,36.5828229 30.4692942,36.5828229 31.0412466,36.0115313 L32.1411549,34.9275422 L32.507791,34.5466812 L41.5710357,25.4939074 C42.1429881,24.9226158 42.1429881,23.9997602 41.5710357,23.4284687 C40.9990834,22.8571771 40.0751604,22.8571771 39.5032081,23.4284687 Z"
            transform="translate(30.000000, 29.720000) rotate(-90.000000) translate(-30.000000, -29.720000) "
          ></path>
        </g>
      </g>
    </svg>
  </Wrapper>
)

const Wrapper = styled(Link)`
  width: 60px;
  height: 60px;
  background-color: #fff;
  transition: all 0.3s linear;
  color: ${colors.blue};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: perspective(1px) translateZ(0);
  border-radius: 4px;
  margin: 0 auto;
  &:hover {
    color: #fff;
  }
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${colors.blue};
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    border-radius: 4px;
  }
  &:hover:before {
    transform: scaleX(1);
  }
`
