import React from "react"

export default () => (
  <svg width="23px" height="32px" viewBox="0 0 23 32">
    <title>Arrow</title>
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g fillRule="nonzero">
        <path
          d="M17.205,5.007 C16.776,4.563 16.062,4.563 15.618,5.007 C15.189,5.436 15.189,6.15 15.618,6.578 L23.665,14.625 L-2.889,14.625 C-3.508,14.626 -4,15.118 -4,15.737 C-4,16.356 -3.508,16.864 -2.889,16.864 L23.665,16.864 L15.618,24.896 C15.189,25.34 15.189,26.055 15.618,26.483 C16.062,26.927 16.777,26.927 17.205,26.483 L27.157,16.531 C27.601,16.102 27.601,15.388 27.157,14.96 L17.205,5.007 Z"
          id="Path"
          transform="translate(11.745000, 15.745000) rotate(-270.000000) translate(-11.745000, -15.745000) "
        />
      </g>
    </g>
  </svg>
)
