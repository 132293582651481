import _ from "lodash"
import React from "react"
import styled from "styled-components"

import colors from "../../styles/colors"
import below from "../../styles/media"

const StatsWrapper = styled.div`
  color: ${colors.offBlack};
  background-color: ${colors.lightGrey};
  padding: 57px 10px;

  h3 {
    max-width: 1080px;
    margin: 20px auto 35px auto;
    font-weight: 400;
    font-size: 25px;
    letter-spacing: 0.18px;
    text-align: center;
  }
`
const StatItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1080px;
  margin: 0 auto;

  ${below.tablet`
    flex-wrap: wrap;
  `}
`
const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 188px;
  width: 188px;
  background-color: #59CFF5;
  color: #FFFFFF;
  border-radius: 50%;

  h4 {
    margin: 0;
    font-size: 49px;
    font-weight: 700;
    letter-spacing: 0.35px;
    line-height: 60px;
    text-align: center;
  }
  h5 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.11px;
    line-height: 20px;
    text-align: center;
  }

  ${below.tablet`
    width: auto;
    max-width: 85px;
    height: 80px;
    flex: 1 0 auto;
    margin: 0 0 30px;
    h4 {
      font-size: 25px;
      line-height: 20px;
    }
    h5 {
      font-size: 10px;
    }
  `}
`

export const Stat = ({ label, stat }) => (
  <StatItem>
    <h4>{stat}</h4>
    <h5>{label}</h5>
  </StatItem>
)

export const StatsSection = ({ title, stats }) => {
  return (
    <StatsWrapper>
      <h3>{title}</h3>
      <StatItems>
        {_.map(stats, (stat, i) => (
          <Stat key={i} {...stat} />
        ))}
      </StatItems>
    </StatsWrapper>
  )
}

export default StatsSection
