import { graphql } from "gatsby"

export const locations = graphql`
  fragment LocationsFragment on AirtableConnection {
    edges {
      node {
        recordId
        data {
          address
          city
          country
          email
          icon {
            url
          }
          image {
            url
          }
          latitude
          longitude
          inFooter
          weight
          mapZoom
        }
      }
    }
  }
`

export const sports = graphql`
  fragment SportsFragment on AirtableConnection {
    edges {
      node {
        recordId
        data {
          sport
          name
        }
      }
    }
  }
`

export const clients = graphql`
  fragment ClientsFragment on AirtableConnection {
    edges {
      node {
        recordId
        data {
          client
          name
          quotes
          weight
          includeThe
          logo {
            url
            size
          }
        }
      }
    }
  }
`
export const products = graphql`
  fragment ProductsFragment on AirtableConnection {
    edges {
      node {
        recordId
        data {
          product
          name
          description
          weight
          subtitle
          longDescription
          homepage
          icon {
            url
            size
          }
        }
      }
    }
  }
`
export const caseStudies = graphql`
  fragment CaseStudiesFragment on AirtableConnection {
    edges {
      node {
        recordId
        data {
          case
          name
          productID
          clientID
          sportID
          weight
          homepage
          link
          subtitle
          description
          quote
          similar
          cardImage {
            url
          }
          bannerImage {
            url
          }
          bannerLogo {
            url
          }
        }
      }
    }
  }
`

export const quotes = graphql`
  fragment QuotesFragment on AirtableConnection {
    edges {
      node {
        recordId
        data {
          quote
          person
          title
          quoteText
          clientID
          weight
          CaseStudies,
          show
        }
      }
    }
  }
`

// export const employees = graphql`
//   fragment EmployeesFragment on AirtableConnection {
//     edges {
//       node {
//         recordId
//         data {
//           name
//           image {
//             url
//           }
//           position
//           weight
//         }
//       }
//     }
//   }
// `
export const caseStudyCards = graphql`
  fragment CaseStudyCardsFragment on AirtableConnection {
    edges {
      node {
        recordId
        data {
          name
          caseStudy
          type
          image {
            url
          }
          title
          description
          column
          startShown
          weight
        }
      }
    }
  }
`
export const stats = graphql`
  fragment StatsFragment on AirtableConnection {
    edges {
      node {
        recordId
        data {
          stat
          label
          weight
        }
      }
    }
  }
`

export const all_static = graphql`
  fragment StaticContentFragment on AirtableConnection {
    edges {
      node {
        data {
          name
          content
        }
      }
    }
  }
`
